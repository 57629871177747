/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { Applications } from '../enums/applications.enum';
import { ApiService } from './ApiService';
import { LoggerService } from './LoggerService';
import { PushNotificationService } from './PushNotificationService';
import { StorageService } from './StorageService';
import { Utils } from './Utils';
import {
	ParsedMessage,
	ChatMessageCarousel,
	CLIENT_TRIGGERED_EVENTS,
	COMMUNICATION_MEDIUMS,
	GenericError,
	MESSAGE_USER_TYPE,
	ParsedRoom,
	QuickReply,
	RawBotResponse,
	ROOM_TYPES,
	SERVER_TRIGGERED_EVENTS,
	BotMessageRequestBody,
	RawMessageFromSocket,
	RawQuickReply,
	ChatMessageText,
} from '../types';
import { MESSAGES } from '../messages';
import { exitQuickReply, mainMenuQuickReply } from '../constants';
import { GPTService } from './GPTService';

// Constants
const API_URL = process.env.REACT_APP_API_URL as string;
const FAILED_MESSAGE = 'We are unable to reach our servers at the moment. Please try again later.';
export interface MessageEventsFromWidget extends MessageEvent {
	data: {
		chatOpened: boolean;
	};
}

let isSocketConnected = false;
const disabledStudentMessage =
	'The student is currently disabled. You cannot communicate with this student.';
export class ChatService {
	messages$ = new Subject<ParsedMessage>();

	typing$ = new Subject<boolean>();

	quickReply$ = new Subject<QuickReply[]>();

	private commMedium: COMMUNICATION_MEDIUMS = COMMUNICATION_MEDIUMS.API;

	private socket: Socket | null = null;

	private readonly app: Applications = Applications.CHATBOT;

	private room: Partial<ParsedRoom> | null;

	constructor(room: ParsedRoom | null) {
		this.app = StorageService.application;
		this.room = room;
	}

	// Socket
	private setupSocket() {
		this.socket = io(`${API_URL}/chat`, {
			transports: ['websocket'],
			reconnection: true,
			auth: {
				accessToken: StorageService.accessToken,
			},
		});
		const onConnection = () => {
			// eslint-disable-next-line no-console
			console.log('Socket connected...');
			if (StorageService.application === Applications.CHATBOT) {
				this.quickReply$.next(exitQuickReply);
			}
			ChatService.enableTextAndVoice();
			ChatService.removeTitleOnTextAndVoice();
			this.commMedium = COMMUNICATION_MEDIUMS.SOCKET;
			isSocketConnected = true;
			this.socket?.emit(CLIENT_TRIGGERED_EVENTS.JOIN, { room_id: this.room?._id });
		};
		const onDisconnection = (reason: string) => {
			// eslint-disable-next-line no-console
			console.log('disconnect');
			if (reason === 'io server disconnect' || reason === 'transport close') {
				isSocketConnected = false;
			} else {
				this.commMedium = COMMUNICATION_MEDIUMS.API;
			}
		};
		const onMessageReceived = (rawLiveAgentMessage: RawMessageFromSocket[]) => {
			// TODO: check the effect of this change on live agent and student advisor dashboard
			rawLiveAgentMessage.forEach((messageObject) => {
				if (messageObject.content?.text) {
					const message = Utils.createTextMessageObj({
						userId: messageObject.user_id,
						text: Utils.linkifyAndSanitizeWithWhitelistedTags(
							messageObject.content.text as string,
						).trim(),
						messageCreator: messageObject.user_type,
						name:
							StorageService.application !== Applications.CHATBOT ? this.room?.title : undefined,
					});
					if (message) {
						this.messages$.next(message);
						if (StorageService.application === Applications.CHATBOT) {
							StorageService.storeMessage(message);
						}
					}
				}
				if (messageObject.content?.quick_replies?.length) {
					this.quickReply$.next(
						messageObject.content.quick_replies.map((quickReply) => ({ name: quickReply.display })),
					);
				}
			});
		};
		const onClose = () => {
			if (this.app === Applications.CHATBOT) {
				this.room = null;
				StorageService.roomId = null;
				StorageService.accessToken = null;
			}
			this.socket?.disconnect();
		};

		const onError = (error: unknown) => {
			const typedError = error as GenericError;
			LoggerService.logError(error);
			// TODO: add message below
			if (StorageService.application === Applications.CHATBOT) {
				switch (typedError.statusCode) {
					case 401:
					case 404:
						this.messages$.next(
							Utils.createTextMessageObj({
								text: MESSAGES.ROOM_CLOSED,
								messageCreator: MESSAGE_USER_TYPE.BOT,
							}),
						);
						break;
					default:
						this.messages$.next(
							Utils.createTextMessageObj({
								text: MESSAGES.GENERIC_CHAT_ERROR,
								messageCreator: MESSAGE_USER_TYPE.SYSTEM,
							}),
						);
						break;
				}
				this.quickReply$.next(mainMenuQuickReply);
				this.leaveRoom();
			} else {
				const message = Utils.getErrorMessage(typedError.status);
				this.messages$.next(
					Utils.createTextMessageObj({
						text: message,
						messageCreator: MESSAGE_USER_TYPE.SYSTEM,
					}),
				);
			}
		};
		this.socket.on('connect', onConnection);
		this.socket.on('disconnect', onDisconnection);
		this.socket.on(SERVER_TRIGGERED_EVENTS.MESSAGE, onMessageReceived);
		this.socket.on(SERVER_TRIGGERED_EVENTS.CLOSE, onClose);
		this.socket.on('exception', onError);
	}

	private messageSocket(text: string) {
		if (isSocketConnected) {
			this.socket?.emit(CLIENT_TRIGGERED_EVENTS.MESSAGE, {
				message: text,
				room_id: this.room?._id,
			});
		} else {
			this.messages$.next(
				Utils.createTextMessageObj({
					text: MESSAGES.GENERIC_CHAT_ERROR,
					messageCreator: MESSAGE_USER_TYPE.SYSTEM,
				}),
			);
		}
	}

	private async leaveRoom() {
		try {
			this.room = null;
			StorageService.roomId = null;
			StorageService.accessToken = null;
			if (this.socket) {
				this.socket.disconnect();
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('failed to close room', err);
		}
	}

	// Generates quick replies for bot messages
	// if no quick reply returns main menu quick reply
	// Dialogflow
	static generateQuickRepliesForChatbot(quickReplies: RawQuickReply[] | undefined): QuickReply[] {
		if (quickReplies && quickReplies.length) {
			return quickReplies.map((val) => ({ name: val?.display }));
		}
		return mainMenuQuickReply;
	}

	/**
	 * Parses the Dialogflow response and converts it to a consumable format.
	 * @param rawResponse The API response from Dialogflow
	 */
	private parseBotResponse(rawResponse: RawBotResponse): ParsedMessage[] {
		const messages: ParsedMessage[] = [];

		rawResponse?.data.messages.forEach((message) => {
			if (message?.text) {
				messages.push({
					_id: Utils.unique(),
					type: 'text',
					position: 'left',
					user: {
						type: MESSAGE_USER_TYPE.BOT,
					},
					content: { text: Utils.linkifyAndSanitizeWithWhitelistedTags(message.text) },
					quickReplies: ChatService.generateQuickRepliesForChatbot(message?.quick_replies),
				});
			}
			if (message?.card) {
				messages.push({
					_id: Utils.unique(),
					type: 'card',
					position: 'left',
					user: {
						type: MESSAGE_USER_TYPE.BOT,
					},
					content: {
						title: message.card.title,
						subTitle: Utils.linkifyAndSanitizeWithWhitelistedTags(message.card.sub_title),
						image: message.card.image,
						actions: message.card.actions.map((a) => ({
							display: a.title,
							link: a.url,
						})),
					},
					quickReplies: ChatService.generateQuickRepliesForChatbot(message?.quick_replies),
				});
			}

			if (message?.carousel) {
				const carouselMessage: ChatMessageCarousel = {
					_id: Utils.unique(),
					type: 'carousel',
					position: 'left',
					user: {
						type: MESSAGE_USER_TYPE.BOT,
					},
					content: { cards: [] },
					quickReplies: ChatService.generateQuickRepliesForChatbot(message?.quick_replies),
				};
				carouselMessage.content.cards = message?.carousel.map((card) => ({
					title: card.title,
					subTitle: Utils.linkifyAndSanitizeWithWhitelistedTags(card.sub_title),
					image: card.image,
					actions: card.actions.map((a) => ({ display: a.title, link: a.url })),
				}));
				messages.push(carouselMessage);
			}
		});

		if (rawResponse.data?.room_id) {
			this.room = {
				_id: rawResponse.data.room_id,
			};
			StorageService.roomId = rawResponse.data.room_id;
		}

		if (rawResponse.data?.access_token) {
			StorageService.accessToken = rawResponse.data.access_token;
			ChatService.disableTextVoiceAndOtherOptions();
			this.setupSocket();
		}

		return messages;
	}

	/**
	 * Calls Dialogflow API with the query
	 * @param textMessage The text message to be sent
	 */
	private async messageBot(textMessage: string, hidden?: boolean) {
		const requestObj: BotMessageRequestBody = {
			text: textMessage,
		};
		if (this.room?._id) {
			requestObj.room_id = this.room._id;
		}
		if (hidden) {
			requestObj.hidden = true;
		}
		this.typing$.next(true);
		const messages: ParsedMessage[] = [];
		try {
			const rawResponse = await ApiService.messageBot(requestObj);
			if (rawResponse) {
				const message = await this.parseBotResponse(rawResponse);
				messages.push(...message);
			} else {
				throw Error('no data in bot response');
			}
			this.typing$.next(false);
			if (rawResponse?.data?.room_id) {
				StorageService.roomId = rawResponse.data.room_id;
				this.room = {
					_id: rawResponse.data.room_id,
				};
			}
		} catch (error) {
			this.typing$.next(false);
			LoggerService.logError(error);
			const message = Utils.createTextMessageObj({
				text: FAILED_MESSAGE,
				messageCreator: MESSAGE_USER_TYPE.BOT,
			});
			messages.push(message);
		}
		messages.forEach((message) => {
			this.messages$.next(message);
			if (
				message.user.type !== MESSAGE_USER_TYPE.GUEST &&
				message.user.type !== MESSAGE_USER_TYPE.BOT
			) {
				PushNotificationService.sendMessage(message);
			}
			if (this.app === Applications.CHATBOT) {
				StorageService.storeMessage(message);
			}
		});
	}

	private sendFirstMessage(event: MessageEventsFromWidget) {
		if (event.data.chatOpened) {
			const history = StorageService.allMessages();
			if (!history.length) {
				this.sendMessage('Main Menu', StorageService.application, true);
			}
		}
	}

	static removeTitleOnTextAndVoice() {
		const textInput = document.getElementsByClassName(
			'Composer-input',
		)[0] as unknown as HTMLTextAreaElement;
		const voiceInput = document.getElementsByClassName(
			'Composer-inputTypeBtn',
		)[0] as unknown as HTMLButtonElement;
		if (textInput) {
			textInput.removeAttribute('title');
		}
		if (voiceInput) {
			voiceInput.removeAttribute('title');
		}
	}

	static setTitleOnTextAndVoice(message: string) {
		const textInput = document.getElementsByClassName(
			'Composer-input',
		)[0] as unknown as HTMLTextAreaElement;
		const voiceInput = document.getElementsByClassName(
			'Composer-inputTypeBtn',
		)[0] as unknown as HTMLButtonElement;
		if (textInput) {
			textInput.title = message;
		}
		if (voiceInput) {
			voiceInput.title = message;
		}
	}

	static disableTextVoiceAndOtherOptions() {
		const textInput = document.getElementsByClassName(
			'Composer-input',
		)[0] as unknown as HTMLTextAreaElement;
		const voiceInput = document.getElementsByClassName(
			'Composer-inputTypeBtn',
		)[0] as unknown as HTMLButtonElement;
		if (textInput) {
			textInput.disabled = true;
		}
		if (voiceInput) {
			voiceInput.disabled = true;
		}
	}

	static disableEndChatButton() {
		const endButton = document.getElementById('end-chat-button') as HTMLButtonElement;
		if (endButton) {
			endButton.disabled = true;
		}
	}

	static enableEndChatButton() {
		const endButton = document.getElementById('end-chat-button') as HTMLButtonElement;
		if (endButton) {
			endButton.disabled = false;
		}
	}

	static disableTransferChatButton() {
		const transferButton = document.getElementById('transfer-chat-button') as HTMLButtonElement;
		if (transferButton) {
			transferButton.disabled = true;
		}
	}

	static enableTransferChatButton() {
		const transferButton = document.getElementById('transfer-chat-button') as HTMLButtonElement;
		if (transferButton) {
			transferButton.disabled = false;
		}
	}

	static enableTextAndVoice() {
		const textInput = document.getElementsByClassName(
			'Composer-input',
		)[0] as unknown as HTMLTextAreaElement;
		const voiceInput = document.getElementsByClassName(
			'Composer-inputTypeBtn',
		)[0] as unknown as HTMLButtonElement;
		if (textInput) {
			textInput.disabled = false;
		}
		if (voiceInput) {
			voiceInput.disabled = false;
		}
	}

	static setTextInputLength(length: number) {
		const textInput = document.getElementsByClassName(
			'Composer-input',
		)[0] as unknown as HTMLTextAreaElement;
		if (textInput) {
			textInput.maxLength = length;
		}
	}

	// Common functions
	async init() {
		ChatService.setTextInputLength(2048);
		if (
			(this.app === Applications.CHATBOT || this.app === Applications.CHATBOT_GPT) &&
			StorageService.roomId
		) {
			this.room = {
				_id: StorageService.roomId,
			};
			ChatService.enableTextAndVoice();
		}
		if (
			(this.app === Applications.CHATBOT && StorageService.accessToken && StorageService.roomId) ||
			(this.app === Applications.EXTERNAL_CHAT && this.room) ||
			(this.app === Applications.LIVE_AGENT_DASHBOARD && this.room)
		) {
			this.commMedium = COMMUNICATION_MEDIUMS.SOCKET;
			ChatService.disableTextVoiceAndOtherOptions();
			if (this.room?.status === ROOM_TYPES.DISABLED || this.room?.endedAt) {
				if (this.room.status === ROOM_TYPES.DISABLED) {
					ChatService.setTitleOnTextAndVoice(disabledStudentMessage);
				}
				return; // no need to setup socket if the room is disabled.
			}
			this.setupSocket();
			// eslint-disable-next-line no-console
			console.log('connecting to socket');
		}
	}

	static async fetchHistory(
		app: Applications,
		room: ParsedRoom | null,
		lastMessageId?: string,
	): Promise<ParsedMessage[]> {
		const messages: ParsedMessage[] = [];
		if (app === Applications.CHATBOT || app === Applications.CHATBOT_GPT) {
			StorageService.checkHistory();
			return StorageService.allMessages();
		}
		if ((app === Applications.EXTERNAL_CHAT || app === Applications.LIVE_AGENT_DASHBOARD) && room) {
			messages.push(...(await ApiService.fetchConversation(room, lastMessageId)));
		}
		return messages;
	}

	sendMessage(text: string, app: Applications, hidden = false): void {
		switch (this.commMedium) {
			case COMMUNICATION_MEDIUMS.API: {
				const userMessage = Utils.createTextMessageObj({
					text: text.trim(),
					messageCreator: MESSAGE_USER_TYPE.GUEST,
				});
				if (!hidden) {
					StorageService.storeMessage(userMessage);
					this.messages$.next(userMessage);
				}
				if (app === Applications.CHATBOT) {
					this.messageBot(Utils.sanitizeText(text), hidden);
				} else if (app === Applications.CHATBOT_GPT) {
					this.messageGPT(Utils.sanitizeText(text));
				}
				break;
			}
			case COMMUNICATION_MEDIUMS.SOCKET:
				this.messageSocket(Utils.sanitizeText(text));
				break;
			default:
				throw new Error('Invalid user type.');
		}
	}

	close(): void {
		this.socket?.close();
	}

	/**
	 * Messages the GPT server.
	 * @param text The text to send to the GPT server.
	 */
	async messageGPT(text: string) {
		this.typing$.next(true);

		if (!this.room?._id) {
			// If no room exists
			await this.createGPTRoomAndPublishMessage();
		} else {
			// If room exists
			await this.messageGPTAndPublishMessage(text, this.room._id);
		}

		this.typing$.next(false);
	}

	/**
	 * Creates a room on the GPT server and publishes the message.
	 */
	createGPTRoomAndPublishMessage = async () => {
		let messageText: string;
		try {
			const apiResponse = await GPTService.createRoom();
			StorageService.roomId = apiResponse.roomId;
			this.room = {
				_id: apiResponse.roomId,
			};
			messageText = apiResponse.message;
		} catch (error) {
			messageText = FAILED_MESSAGE;
		}
		const message = Utils.createTextMessageObj({
			text: messageText,
			messageCreator: MESSAGE_USER_TYPE.BOT,
		});
		this.messages$.next(message);
		StorageService.storeMessage(message);
	};

	/**
	 * Messages the GPT server and publishes the message.
	 * @param text The text to send to the GPT server.
	 * @param roomId The room id of the room to send the message to.
	 */
	messageGPTAndPublishMessage = async (text: string, roomId: string) => {
		const response$ = new Subject<string>();
		const uniqueId =
			Date.parse(new Date().toISOString()) +
			performance.now().toString() +
			Math.floor(Math.random() * 10);
		const subscription = response$.subscribe((response) => {
			this.publishStreamedGPTMessage(response, uniqueId);
		});
		let fullMessage: ChatMessageText;
		try {
			const fullResponse = await GPTService.sendMessage(text, roomId, response$);
			fullMessage = Utils.createTextMessageObj({
				text: fullResponse,
				messageCreator: MESSAGE_USER_TYPE.BOT,
			});
		} catch (error) {
			fullMessage = Utils.createTextMessageObj({
				text: FAILED_MESSAGE,
				messageCreator: MESSAGE_USER_TYPE.BOT,
			});
			this.messages$.next(fullMessage);
		}
		StorageService.storeMessage(fullMessage);
		subscription.unsubscribe();
	};

	/**
	 * Publishes a streamed GPT message.
	 * @param message The message to publish.
	 * @param id The id of the message.
	 */
	publishStreamedGPTMessage = (message: string, id: string) => {
		const parsedMessage = Utils.createTextMessageObj({
			text: message,
			messageCreator: MESSAGE_USER_TYPE.BOT,
		});
		this.messages$.next({ ...parsedMessage, _id: id });
	};
}
