/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export enum Applications {
	CHATBOT = 'CHATBOT',
	EXTERNAL_CHAT = 'EXTERNAL_CHAT',
	LIVE_AGENT_DASHBOARD = 'LIVE_AGENT_DASHBOARD',
	CHATBOT_GPT = 'CHATBOT_GPT',
}
