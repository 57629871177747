/* eslint-disable max-len */
/*
 * Copyright (C) iSchoolConnect - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { findPhoneNumbersInText } from 'libphonenumber-js';
import linkifyHtml from 'linkifyjs/html';
import { FilterXSS } from 'xss';
import { Applications } from '../enums/applications.enum';
import { MESSAGES } from '../messages';
import {
	ChatMessageText,
	DEVICE_TYPE,
	MessageContentCard,
	MessageContentCarousel,
	MessageContentText,
	MESSAGE_USER_TYPE,
	ParsedMessage,
	RawMessageFromAPIResponse,
} from '../types';
import { StorageService } from './StorageService';

const xssFilterWithWhitelistedTagsForRichText = new FilterXSS({
	whiteList: {
		p: [],
		span: ['style'],
		strong: [],
		br: [],
	},
	stripIgnoreTag: true,
});

const entityMap: Record<string, string> = {
	'&': '&amp;',
	'<': '&lt;',
	'>': '&gt;',
	'"': '&quot;',
	"'": '&#39;',
};

const telify = (origText: string): string => {
	let newText = origText;
	const getPhoneNum = findPhoneNumbersInText(newText);

	if (newText?.length) {
		getPhoneNum.forEach((match) => {
			const pattern = origText.substring(match.startsAt, match.endsAt);
			newText = newText.replace(pattern, `<a href="tel:${match.number.number}")>${pattern}</a>`);
		});
	}
	return newText;
};

/**
 * returns if the iframe is expanded or not
 * the iframe is expanded only on devices having width more than 500 (non-mobile devices)
 * and, on those devices the width for chat window is set to 375px
 * So, if the width is 500 or more, that means it is in expanded mode
 */
const isFrameExpanded = (): boolean => {
	const chatContainerElement = document.getElementById('chat-wrapper');
	return !!(chatContainerElement && chatContainerElement.offsetWidth > 500);
};

/**
 * Returns an id based on time. This is for some quick UI indexing. Not a replacement to a DB id.
 * @returns A "unique" id
 */
const unique = (): string => Date.parse(new Date().toISOString()) + performance.now().toString();

/**
 * Adds anchor tags for email/phone/url if there are any present in the text
 * Sanitizes the text
 * Allows the whitelisted tags required for rich text (used in chatbot responses)
 * @param plainText Regular text than may contain phone/email
 * @returns HTML text
 */
const linkifyAndSanitizeWithWhitelistedTags = (plainText: string): string =>
	telify(
		linkifyHtml(
			xssFilterWithWhitelistedTagsForRichText.process(plainText).replace(/\n/gi, '<br />'),
			{
				defaultProtocol: 'https',
				target: '_blank',
			},
		),
	);

/**
 * Sanitizes a text to HTML safe
 * @param plainText The normal text potentially with some HTML tags
 * @returns HTML safe string
 */
const sanitizeText = (plainText: string): string =>
	String(plainText).replace(/[&<>"'`=]/g, (s) => entityMap[s]);

/**
 * Adds anchor tags for email/phone/url if there are any present in the text
 * Sanitizes the text
 * @param plainText Regular text than may contain phone/email
 * @returns HTML text
 */
const linkifyAndSanitize = (plainText: string): string =>
	telify(
		linkifyHtml(sanitizeText(plainText).replace(/\n/gi, '<br />'), {
			defaultProtocol: 'https',
			target: '_blank',
		}),
	);

const openLink = (url: string): Window | null => window.open(url, '_blank');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const clone = (json: unknown): any => JSON.parse(JSON.stringify(json));

const getMessageCreatorName = (data: {
	userName?: string;
	botName?: string;
	messageCreator: MESSAGE_USER_TYPE;
}): string | undefined => {
	switch (data.messageCreator) {
		case MESSAGE_USER_TYPE.BOT:
			return data.botName;
		default:
			return data.userName;
	}
};

const getMessagePosition = (
	messageCreator: MESSAGE_USER_TYPE,
	userId?: string,
): 'left' | 'right' => {
	let position!: 'right' | 'left';
	const { application } = StorageService;
	switch (application) {
		case Applications.EXTERNAL_CHAT:
		case Applications.LIVE_AGENT_DASHBOARD:
			position = userId === StorageService?.authUser?._id ? 'right' : 'left';
			break;
		case Applications.CHATBOT:
		case Applications.CHATBOT_GPT:
			// guest right, rest left
			position = messageCreator === MESSAGE_USER_TYPE.GUEST ? 'right' : 'left';
			break;
		default:
			throw Error('Invalid application type');
	}
	return position;
};

const createTextMessageObj = (data: {
	messageCreator: MESSAGE_USER_TYPE;
	id?: string;
	name?: string;
	botName?: string;
	dateAndTime?: string | Date;
	userId?: string;
	text: string;
}): ChatMessageText => {
	const position = getMessagePosition(data.messageCreator, data.userId);
	const messageCreatorName = getMessageCreatorName({
		botName: data.botName,
		userName: data.name,
		messageCreator: data.messageCreator,
	});
	const dateAndTime = data.dateAndTime ? data.dateAndTime : new Date();
	return {
		_id: data.id || unique(),
		type: 'text',
		position,
		user: {
			name:
				position === 'left' && StorageService.application !== Applications.CHATBOT
					? messageCreatorName
					: undefined,
			type: data.messageCreator,
		},
		dateAndTime: StorageService.application !== Applications.CHATBOT ? dateAndTime : undefined,
		content: { text: data.text },
	};
};

// 3 types of messages
// 1 socket - let it be handled by the old function
// 2 bot response - let it be handled the way it is handled
// 3 API response - this is what we have to handle
// TODO: Generalise the message response format on backend and let all the messages be later be parsed by the same function.
const parseFetchConversationResponse = (message: RawMessageFromAPIResponse): ParsedMessage => {
	const position = getMessagePosition(message.user_type, message?.user_id);
	const messageCreatorName = getMessageCreatorName({
		botName: message.bot?.name,
		userName: message.user?.name,
		messageCreator: message.user_type,
	});
	let content = message.content as any;
	let type!: 'card' | 'carousel' | 'text';
	if (content.card) {
		type = 'card';
		content = {
			title: content.card?.title,
			subTitle: content.card?.sub_title,
			image: content.card?.image,
			actions: content.card?.actions.map((a: { title: string; url: string }) => ({
				display: a.title,
				link: a.url,
			})),
		} as MessageContentCard;
	}
	if (content.carousel && content.carousel?.length) {
		type = 'carousel';
		content = {
			cards: content?.carousel.map((card: any) => ({
				title: card?.title,
				subTitle: card?.sub_title,
				image: card?.image,
				actions: card?.actions.map((a: { title: string; url: string }) => ({
					display: a.title,
					link: a.url,
				})),
			})),
		} as MessageContentCarousel;
	}
	if (content.text) {
		type = 'text';
		content = content as MessageContentText;
	}
	return {
		_id: message._id,
		type,
		position,
		user: {
			userId: message.user_id,
			name:
				position === 'left' && StorageService.application !== Applications.CHATBOT
					? messageCreatorName
					: undefined,
			type: message.user_type,
		},
		dateAndTime: message.created_at,
		content,
		quickReplies: [],
		hidden: false,
	};
};

const getTrimmedText = (text: string, maxLength = 20): string =>
	text.length > maxLength ? text.substr(0, maxLength).concat('...') : text;

const sleep = (timeout = 1000): Promise<unknown> =>
	new Promise((res) => {
		setTimeout(res, timeout);
	});

const getErrorMessage = (status?: number): string => {
	switch (status) {
		case 403:
			return MESSAGES.FORBIDDEN_ERROR;
		default:
			return MESSAGES.GENERIC_CHAT_ERROR;
	}
};

const getDeviceType = (): string => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		return DEVICE_TYPE.TABLET;
	}
	if (
		/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua,
		)
	) {
		return DEVICE_TYPE.MOBILE;
	}
	return DEVICE_TYPE.DESKTOP;
};

const capitalize = (string: string | undefined) =>
	string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';

const getPrimaryColor = () =>
	StorageService.authTenant?.brandingConfig.theme.primary
		? StorageService.authTenant?.brandingConfig.theme.primary
		: '#00aff0';
const getSecondaryColor = () =>
	StorageService.authTenant?.brandingConfig.theme.secondary
		? StorageService.authTenant?.brandingConfig.theme.secondary
		: '#00aff0';

export const Utils = {
	telify,
	unique,
	linkifyAndSanitize,
	linkifyAndSanitizeWithWhitelistedTags,
	openLink,
	isFrameExpanded,
	clone,
	createTextMessageObj,
	sleep,
	sanitizeText,
	getTrimmedText,
	getErrorMessage,
	getDeviceType,
	parseFetchConversationResponse,
	capitalize,
	getPrimaryColor,
	getSecondaryColor,
};
